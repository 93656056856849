import { CCApiService } from './api-service';
import { CellType, Region, UIConfig } from './interfaces';

export class RegionsService {
  static ApiService: CCApiService;
  static regionsAPI: string;
  static apiBasePath: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.regionsAPI = `${this.apiBasePath}/regions`;
  }

  static async getRegions(cellType: CellType): Promise<Region[]> {
    const url = `${this.regionsAPI}?cellType=${cellType}`;
    return this.ApiService.performFetch(url);
  }
}
